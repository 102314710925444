@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --layout-background-color: #f4f7fe;
}
.c-app {
  font-family: "Montserrat", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-message {
  z-index: 9999 !important;
  font-family: "Montserrat", sans-serif;
}
.list-item {
  margin: 5px;
  width: 100%;
  background-color: #f5f7f6;
  border: 0;
  justify-content: left;
  padding: 1px;
  border-radius: 5px;
}
.item {
  padding-left: 5px;
  justify-content: left;
}
.list {
  width: 300px;
  background-color: #f5f7f6;
  border: transparent;
}
#List-component {
  /* padding-top: 100px; */
  justify-content: center;
  margin: 50px auto;
}
.list-component {
  /* justify-content: center; */
  margin: 20px auto;
  padding-top: 110px;
}
#add-to-list-txt {
  color: navy;
  font-size: 15px;
  padding-top: 10px;
}
#user-icon {
  color: #01223d;
}
#user-name {
  text-align: left;
  margin-left: 0px;
  padding-left: 0px;
  font-size: 15px;
  color: #01223d;
}
.card-list-body {
  justify-content: left;
}
.card-footer {
  border: 0 !important;
}
.full-width {
  width: 100%;
}
.card-body {
  padding: 2rem !important;
  min-height: auto;
}
.select-box-style {
  text-align: left;
  border-radius: 0.6rem;
  border: none;
}
.c-cancel-button {
  font-size: 35px;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  text-align: center;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #001e54;
  color: #ffffff;
  border: 1px solid #001e54;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #00000029 !important;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
  background-color: none !important;
  border: 1px solid #00000029;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #00000029 !important;
  border-right-width: 1px !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: none !important;
}

.spinner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-10px, -10px);
}

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.ant-select-selector {
  border-radius: 0.6rem !important;
  border-width: 1px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  padding: 0 11px;
}
/* .sub-label {
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  color: #001e54;
} */
.page-template-title {
  color: #001e54;
  font-size: 25px;
  font-weight: 500;
}

.ant-message .anticon {
  position: relative;
  top: -3px;
  margin-right: 8px;
  font-size: 16px;
}
.ca-sub-label {
  margin-bottom: 20px;
  font-weight: 500;
  color: #4b4b4b;
  font-family: "poppins";
  font-size: 1rem;
}
.ant-popover-arrow {
  visibility: hidden !important;
}

.ant-tooltip-arrow-content {
  background-color: #e4ecff !important;
  box-shadow: none !important;
}

html {
  --antd-wave-shadow-color: none !important;
}
.ant-select-selector {
  box-shadow: none !important;
}
.ant-select-selector:hover {
  outline: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: none !important;
  background-color: none !important;
  border: none !important;
  border-radius: none !important;
}
