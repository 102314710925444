.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*---start--global styling---*/

.btn-big-right {
  min-width: 200px;
}
.text-primary {
  color: #001e54 !important;
}
.text-color-gray {
  color: #4b4b4b75;
}
.secondary-title {
  font-size: 18px;
  font-weight: 600;
  padding-left: 0px;
  color: #4b4b4b;
  font-family: "poppins";
}
/*---end--global styling---*/
/*---start-- no contact list---*/
.no-contact-list .icon {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  padding: 15px;
  background-color: #001e540f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-contact-list .icon svg {
  color: #7a7a7a;
}
.no-contact-list h2 {
  font-size: 20px;
  color: #7a7a7a;
}
.no-contact-list h2 p {
  color: #7a7a7a;
}
/*---end-- no contact list---*/
/*---start-- success contact list---*/
.contact-success-msg {
  font-size: 22px;
}
.secondary-contact-msg {
  background-color: #001e540a;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
}
.create-list-section h2 {
  font-size: 15px;
}
.sample-link {
  min-width: 200px;
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  font-size: 9px;
  color: #100e54;
  font-weight: 500;
  text-decoration: underline;
}
.qr-border {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 3%;
}
#qr-message {
  font-family: "Courier New", Courier, monospace;
  font-size: 3vw;
  /* letter-spacing: -3.4px; */
  word-spacing: -5px;
  color: #0e4b94;
  font-weight: 400;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}
